import React from 'react'
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next'
import LangBtn from './LangBtn';
import "./langBtn.css";
import { img42, img43 } from '../GalleryImages';
import {member1,member2,member3,member4,member5,member6,member7,member8,member9,member10,member11,member12,member13,member14} from '../GalleryImages'
import FloatingBtn from "../FloatingBtn"
import FloatingBtn2 from "../FloatingBtn2"
import FloatingBtn3 from "../FloatingBtn3"
const Members = () => {
  const [t, i18n] = useTranslation('global');
  return (
    <div>
        <LangBtn/>
        <FloatingBtn/>
   <FloatingBtn2/>
   <FloatingBtn3/>
      <div className="container mt-5" >
        <div className="row ">
          <div className="col-sm-6"  style={{textAlign:'center'}}><img src={img42} alt="" style={{border:'10px solid #000'}} /> <h4>{t("members.desgn1")}</h4> <h5>{t("members.sarpanch")} </h5> </div>
          <div className="col-sm-6"style={{textAlign:'center'}} ><img src={img43} style={{paddingTop:'1.25rem',border:'10px solid #000'}} alt="" /><h4>{t("members.desgn2")}</h4> <h5>{t("members.upsarpanch")} </h5></div>
        </div>
      </div>
    <div className="container mt-3">
    <div className="row ">
      <div className="col-sm-2 memPhoto">
        <img className='' src={member1} style={{height:'10rem',width:'8rem'}} alt="" />
        <h6>{t("members.mem2")}</h6>
      </div>
      <div className="col-sm-2 memPhoto ">
        <img src={member2} style={{height:'10.1rem',width:'8rem'}} alt="" />
        <h6>{t("members.mem1")}</h6>
      </div>
      <div className="col-sm-2 memPhoto">
        <img src={member3} style={{height:'10.1rem',width:'8rem'}} alt="" />
        <h6>{t("members.mem3")}</h6>
      </div>
      <div className="col-sm-2 memPhoto">
        <img src={member4} style={{height:'10rem',width:'8rem'}} alt="" />
        <h6>{t("members.mem4")}</h6>
      </div>
      <div className="col-sm-2 memPhoto">
        <img src={member5} style={{height:'10.1rem',width:'8rem'}} alt="" />
        <h6>{t("members.mem6")}</h6>
      </div>
      <div className="col-sm-2 memPhoto">
        <img src={member6} style={{height:'10.1rem',width:'8rem'}} alt="" />
        <h6>{t("members.mem7")}</h6>
      </div>

    </div>
    
      </div>
    <div className="container mt-3">
    <div className="row ">
      <div className="col-sm-2 memPhoto">
        <img src={member7} style={{height:'10rem',width:'8rem'}} alt="" />
        <h6>{t("members.mem8")}</h6>
      </div>
      <div className="col-sm-2 memPhoto">
        <img src={member8} style={{height:'10.1rem',width:'8rem'}} alt="" />
        <h6>{t("members.mem14")}</h6>
      </div>
      <div className="col-sm-2 memPhoto">
        <img src={member9} style={{height:'10.1rem',width:'8rem'}} alt="" />
        <h6>{t("members.mem9")}</h6>
      </div>
      <div className="col-sm-2 memPhoto">
        <img src={member10} style={{height:'10rem',width:'8rem'}} alt="" />
        <h6>{t("members.mem10")}</h6>
      </div>
      <div className="col-sm-2 memPhoto">
        <img src={member11} style={{height:'10.1rem',width:'8rem'}} alt="" />
        <h6>{t("members.mem12")}</h6>
      </div>
      <div className="col-sm-2 memPhoto">
        <img src={member12} style={{height:'10.1rem',width:'8rem'}} alt="" />
        <h6>{t("members.mem11")}</h6>
      </div>

    </div>
    </div>
    <div className="container mt-3">
    <div className="row ">
      <div className="col-sm-2 memPhoto">
        <img src={member13} style={{height:'10rem',width:'8rem'}} alt="" />
        <h6>{t("members.mem13")}</h6>
      </div>
      <div className="col-sm-2 memPhoto">
        <img src={member14} style={{height:'10.1rem',width:'8rem'}} alt="" />
        <h6>{t("members.mem5")}</h6>
      </div>
   

    </div>
    </div>
<div className="h2" style={{textAlign:'center',marginTop:'3rem',fontWeight:'700'}}><h1>Members</h1></div>
 <div className="container">
 <Table className='custometable' striped="columns" style={{marginTop:'3rem'}}>
      <thead className='tablehead' style={{backgroundColor:"#003554 !important",color:'#fff'}}>
        <tr>
          <th style={{backgroundColor:"#003554",color:'#fff'}}>{t("members.SrNo")}</th>
          <th style={{backgroundColor:"#003554",color:'#fff'}}>{t("members.Name")}</th>
          <th style={{backgroundColor:"#003554",color:'#fff'}}>{t("members.Designation")}</th>
          <th style={{backgroundColor:"#003554",color:'#fff'}}>{t("members.mobileNo")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t("members.M1")}</td>
          <td>{t("members.sarpanch")}</td>
          <td>{t("members.desgn1")}</td>
          <td>{t("members.m1SP")}</td>
        </tr>
        <tr>
          <td>{t("members.M2")}</td>
          <td>{t("members.upsarpanch")}</td>
          <td>{t("members.desgn2")}</td>
          <td>{t("members.m2USP")}</td>
        </tr>
        <tr>
          <td>{t("members.M3")}</td>
          <td>{t("members.gramvikas")}</td>
          <td>{t("members.desgn3")}</td>
          <td>{t("members.m3GVA")}</td>
        </tr>
        <tr>
          <td>{t("members.M4")}</td>
          <td>{t("members.mem1")}</td>
          <td>{t("members.desgn4")}</td>
          <td>{t("members.m4")}</td>
        </tr>
        <tr>
          <td>{t("members.M5")}</td>
          <td>{t("members.mem2")}</td>
          <td>{t("members.desgn4")}</td>
          <td>{t("members.m5")}</td>
        </tr>
        <tr>
          <td>{t("members.M6")}</td>
          <td>{t("members.mem3")}</td>
          <td>{t("members.desgn4")}</td>
          <td>{t("members.m6")}</td>
        </tr>
        <tr>
          <td>{t("members.M7")}</td>
          <td>{t("members.mem4")}</td>
          <td>{t("members.desgn4")}</td>
          <td>{t("members.m7")}</td>
        </tr>
        <tr>
          <td>{t("members.M2")}</td>
          <td>{t("members.mem5")}</td>
          <td>{t("members.desgn4")}</td>
          <td>{t("members.m8")}</td>

        </tr>
        <tr>
          <td>{t("members.M8")}</td>
          <td>{t("members.mem6")}</td>
          <td>{t("members.desgn4")}</td>
          <td>{t("members.m9")}</td>
        </tr>
        <tr>
          <td>{t("members.M9")}</td>
          <td>{t("members.mem7")}</td>
          <td>{t("members.desgn4")}</td>
          <td>{t("members.m10")}</td>
        </tr>
        <tr>
          <td>{t("members.M10")}</td>
          <td>{t("members.mem8")}</td>
          <td>{t("members.desgn4")}</td>
          <td>{t("members.m11")}</td>
        </tr>
        <tr>
          <td>{t("members.M11")}</td>
          <td>{t("members.mem9")}</td>
          <td>{t("members.desgn4")}</td>
          <td>{t("members.m12")}</td>
        </tr>
        <tr>
          <td>{t("members.M12")}</td>
          <td>{t("members.mem10")}</td>
          <td>{t("members.desgn4")}</td>
          <td>{t("members.m13")}</td>
        </tr>
        <tr>
          <td>{t("members.M13")}</td>
          <td>{t("members.mem11")}</td>
          <td>{t("members.desgn4")}</td>
          <td>{t("members.m14")}</td>
        </tr>
        <tr>
          <td>{t("members.M14")}</td>
          <td>{t("members.mem12")}</td>
          <td>{t("members.desgn4")}</td>
          <td>{t("members.m15")}</td>
        </tr>
        <tr>
          <td>{t("members.M15")}</td>
          <td>{t("members.mem13")}</td>
          <td>{t("members.desgn4")}</td>
          <td>{t("members.m16")}</td>
        </tr>
        <tr>
          <td>{t("members.M16")}</td>
          <td>{t("members.mem14")}</td>
          <td>{t("members.desgn4")}</td>
          <td>{t("members.m17")}</td>
        </tr>
      </tbody>
     
    </Table>
 </div>
 

 


    </div>
  )
}

export default Members