import React from 'react'
import { FaInstagram} from 'react-icons/fa'

function FloatingBtn2() {
  return (
    <div>
         <div className="icons-float" style={{position:'fixed',bottom:'49px',right:'0',zIndex:'100',borderRadius:'50px'}}>
        <div >
        <a href="https://www.instagram.com/grampanchayat_kudus/">  <FaInstagram style={{fontSize:'2rem',backgroundColor:'#0f172a',color:'#fff', padding:'0.2rem',marginBottom:'2rem' }} /></a> 
        
        </div>
       </div>
    </div>
  )
}

export default FloatingBtn2