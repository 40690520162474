import React from "react";
import {img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14,img15,img16,img17,img18,img19,img20,img21,img22,img23,img24,img25,img26,img27,img28,img29,img30,img31,img32,img33,img39,img40,img41, img43, img44, img45, img46, img47, img48, img49} from "../GalleryImages";
// import { FormGroup } from 'react-bootstrap'
import FloatingBtn from "../FloatingBtn"
import FloatingBtn2 from "../FloatingBtn2"
import FloatingBtn3 from "../FloatingBtn3"
import LangBtn from './LangBtn';


export default function Gallery() {

 

  return (
    <>
     <LangBtn/>
   <FloatingBtn/>
   <FloatingBtn2/>
   <FloatingBtn3/>
   <div>
    <div className="h2" style={{textAlign:'center',marginTop:'3rem',fontWeight:'700'}}><h1>Gallery</h1></div>
  <div className="container mt-5 mb-3">
    <div className="row">
      <div className="col-sm-4">
       <a href={img1}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img1} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img2}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img2} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img3}><img style={{width:'100%',height:'auto', objectFit:'cover'}} src={img3} alt="" /></a>
        
      </div>
    </div>
    {/* ////////////////////////////////////////  row2 /////////////////////////////////////////////// */}
    <div className="row mt-3 mb-3">
      <div className="col-sm-4">
       <a href={img4}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img4} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img5}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img5} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img6}><img style={{width:'100%',height:'auto', objectFit:'cover'}} src={img6} alt="" /></a>
        
      </div>
    </div>
    {/* ////////////////////////////////////////// row3 //////////////////////////////////// */}
    <div className="row mt-3 mb-3">
      <div className="col-sm-4">
       <a href={img7}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img7} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img9}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img9} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img8}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img8} alt="" /></a>
        
      </div>
    </div>
    {/* /////////////////////////////////////// row4//////////////////////////////////////////////// */}
    <div className="row mt-3 mb-3">
      <div className="col-sm-4">
       <a href={img10}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img10} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img11}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img11} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img12}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img12} alt="" /></a>
        </div>
    </div>
     {/* /////////////////////////////////////// row5//////////////////////////////////////////////// */}
     <div className="row mt-3 mb-3">
      <div className="col-sm-4">
       <a href={img13}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img14} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img15}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img15} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img13}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img13} alt="" /></a>
        </div>
    </div>
     {/* /////////////////////////////////////// row6//////////////////////////////////////////////// */}
     <div className="row mt-3 mb-3">
      <div className="col-sm-4">
       <a href={img16}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img16} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img17}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img17} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img18}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img18} alt="" /></a>
        </div>
    </div>
     {/* /////////////////////////////////////// row7//////////////////////////////////////////////// */}
     {/* <div className="row mt-3 mb-3">
      <div className="col-sm-4">
       <a href={img16}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img16} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img17}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img17} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img18}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img18} alt="" /></a>
        </div>
    </div> */}
     {/* /////////////////////////////////////// row8//////////////////////////////////////////////// */}
     <div className="row mt-3 mb-3">
      <div className="col-sm-4">
       <a href={img19}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img19} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img20}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img20} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img21}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img21} alt="" /></a>
        </div>
    </div>
      {/* /////////////////////////////////////// row9//////////////////////////////////////////////// */}
      <div className="row mt-3 mb-3">
      <div className="col-sm-4">
       <a href={img22}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img22} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img23}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img23} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img24}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img24} alt="" /></a>
        </div>
    </div>
         {/* /////////////////////////////////////// row10//////////////////////////////////////////////// */}
         <div className="row mt-3 mb-3">
      <div className="col-sm-4">
       <a href={img25}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img25} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img26}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img26} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img27}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img27} alt="" /></a>
        </div>
    </div>
             {/* /////////////////////////////////////// row11//////////////////////////////////////////////// */}
             <div className="row mt-3 mb-3">
      <div className="col-sm-4">
       <a href={img28}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img28} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img29}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img29} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img30}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img30} alt="" /></a>
        </div>
    </div>
               {/* /////////////////////////////////////// row12//////////////////////////////////////////////// */}
               <div className="row mt-3 mb-3">
      <div className="col-sm-4">
       <a href={img31}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img31} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img32}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img32} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img33}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img33} alt="" /></a>
        </div>
    </div>

    {/* /////////////////////////////////////// row13//////////////////////////////////////////////// */}
              <div className="row mt-3 mb-3">
      <div className="col-sm-4">
       <a href={img39}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img39} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img40}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img40} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img41}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img41} alt="" /></a>
        </div>
    </div>
{/* /////////////////////////////////////// row13//////////////////////////////////////////////// */}
<div className="row mt-3 mb-3">
      <div className="col-sm-4">
       <a href={img49}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img49} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img44}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img44} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img45}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img45} alt="" /></a>
        </div>
    </div>
    {/* /////////////////////////////////////// row13//////////////////////////////////////////////// */}
<div className="row mt-3 mb-3">
      <div className="col-sm-4">
       <a href={img46}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img46} alt="" /></a>
        
      </div>
      <div className="col-sm-4">
        <a href={img47}> <img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img47} alt="" /></a>
       
      </div>
      <div className="col-sm-4">
        <a href={img48}><img style={{width:'100%',height:'10rem', objectFit:'cover'}} src={img48} alt="" /></a>
        </div>
    </div>

  </div>
   </div>
    </>
  );
}