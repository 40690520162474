import React from 'react'
import { useTranslation } from 'react-i18next'
import LangBtn from './LangBtn';
import "./langBtn.css";


function Scheme6() {
  const [t, i18n] = useTranslation('global');

  return (
    <div><div className="scheme6h2"><h2>{t("scheme6.s6H2")}</h2></div>
    <div className="scheme6para">
      <p>{t("scheme6.s6paramain")}</p>
      <h4>{t("scheme6.s64eliH4")}</h4>
      <p>{t("scheme6.s6elip1")}</p>
      <p>{t("scheme6.s6elip2")}</p>
      <p>{t("scheme6.s6elip3")}</p>
      <p>{t("scheme6.s6elip4")}</p>
      <p>{t("scheme6.s6benfH4")}</p>
      <p>{t("scheme6.s6benfp1")}</p>
      <h4>{t("scheme6.s6DocH4")}</h4>
      <p>{t("scheme6.s6Docp1")}</p>
      <p>{t("scheme6.s6Docp2")}</p>
      <p>{t("scheme6.s6Docp3")}</p>
      <p>{t("scheme6.s6Docp4")}</p>
      <p>{t("scheme6.s6Docp5")}</p>
      <p>{t("scheme6.s6Docp6")}</p>

    </div>
    <LangBtn/>
    </div>
  )
}

export default Scheme6