import "./App.css";


import { Navbar } from "./Components/Navbar";
import { Routes, Route } from "react-router-dom";
import About from "./Components/Pages/About";
import Members from "./Components/Pages/Members";
import { Schemes } from "./Components/Pages/Schemes";
import Contact from "./Components/Pages/Contact";
import Gallery from "./Components/Pages/Gallery";
import Home from "./Components/Pages/Home";
import CarouselPage from "./Components/CarouselPage";
import Footer from "./Components/Footer";
import 'react-image-gallery/styles/css/image-gallery.css'
import Scheme1 from "./Components/Pages/Scheme1";
import Scheme2 from "./Components/Pages/Scheme2";
import Scheme4 from "./Components/Pages/Scheme4";
import Schemes3 from "./Components/Pages/Schemes3";
import Scheme5 from "./Components/Pages/Scheme5";
import Scheme6 from "./Components/Pages/Scheme6";



function App() {
	return (
		<>
		<div className='App'>
			<Navbar />
			<CarouselPage  />
			<Routes>
			<Route path="/" element={<Home/>} />
				<Route path="/about" element={<About/>} />
				<Route path="/members" element={<Members/>} />
				<Route path="/schemes" element={<Schemes/>} />
				<Route path="/gallery" element={<Gallery/>} />
				<Route path="/contact" element={<Contact/>} /> 
				<Route path="/scheme1" element={<Scheme1/>} /> 
				<Route path="/scheme2" element={<Scheme2/>} /> 
				<Route path="/scheme3" element={<Schemes3/>} /> 
				<Route path="/scheme4" element={<Scheme4/>} />
				<Route path="/scheme5" element={<Scheme5/>} />
				<Route path="/scheme6" element={<Scheme6/>} />
			</Routes>
			<Footer />
		</div>
		</>
	);
}

export default App;
