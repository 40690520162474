import React from 'react'
import { useTranslation } from 'react-i18next'
import "./langBtn.css";

function LangBtn() {

    const [t, i18n] = useTranslation('global');

    const handleLanguage =(language: string ) => {
    i18n.changeLanguage(language);
    
    }

  return (
    <div className='langBtns' style={{backgroundColor:"#fca311",width:'25%',marginLeft:'0rem',paddingLeft:'1rem',paddingTop:'0.5rem',paddingBottom:'0.5rem',border:"2px solid #000",bottom:"-0px",position:'static',bottom:'0px',left:'0',zIndex:'100',borderRadius:'00px'}}>
        <div className="selectLang" style={{backgroundColor:''}}><span style={{fontWeight:'700',color:'#fff'}}>Select Language :</span>
    <a className='LangChangeBtn' style={{padding:'2px 5px',margin:'0rem 0rem 0rem 0.5rem',backgroundColor:"#006494",color:"#fff", borderRadius:'5px '}} onClick={()=> handleLanguage("en")}>English</a>
    <a  className='LangChangeBtn' style={{padding:'2px 5px',margin:'0rem 0.5rem 0rem 0.5rem',backgroundColor:"#006494",color:"#fff",borderRadius:'5px',}} onClick={()=> handleLanguage("em")}>मराठी</a>
          </div>
          </div>
  )
}

export default LangBtn

