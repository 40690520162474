import React from 'react'
import { useTranslation } from 'react-i18next'
import LangBtn from './LangBtn';
import "./langBtn.css";

function Scheme5() {
  const [t, i18n] = useTranslation('global');


  return (
    <div>
      <div className="scheme5h2"><h2>{t("scheme5.s5H2")}</h2></div>
      <div className="scheme5paras">
      <p>{t("scheme5.s5paramain")}</p>
      <h4>{t("scheme5.s54eliH4")}</h4>
      <p>{t("scheme5.s5elip1")}</p>
      <p>{t("scheme5.s5elip2")}</p>
      <p>{t("scheme5.s5elip3")}</p>
      <p>{t("scheme5.s5elip4")}</p>
      <p>{t("scheme5.s5elip5")}</p>
      <p>{t("scheme5.s5elip6")}</p>
      <p>{t("scheme5.s5elip7")}</p>
      <h4>{t("scheme5.s5benfH4")}</h4>
      <p>{t("scheme5.s5benfp1")}</p>
      <p>{t("scheme5.s5benfp2")}</p>
      <p>{t("scheme5.s5benfp3")}</p>
      <p>{t("scheme5.s5benfp4")}</p>
      <p>{t("scheme5.s5benfp5")}</p>
      <h4>{t("scheme5.s5DocH4")}</h4>
      <p>{t("scheme5.s5Docp1")}</p>
      <p>{t("scheme5.s5Docp2")}</p>
      <p>{t("scheme5.s5Docp3")}</p>
      <p>{t("scheme5.s5Docp4")}</p>
      <p>{t("scheme5.s5Docp5")}</p>
    



      </div>
    <LangBtn/>
    </div>
  )
}

export default Scheme5