import React from 'react'
import { NavLink , Link} from 'react-router-dom';
import "./Navbar.css";
import {FaBars} from 'react-icons/fa';
import { useState } from 'react';
import Logo from '../images/logo/brand.jpeg'
import { useTranslation } from 'react-i18next'

export const Navbar = () => {
const [menuOpen, setMenuOpen] = useState(false);


const [t, i18n] = useTranslation('global');

const handleLanguage =(language: string ) => {
i18n.changeLanguage(language);
}



  return (
    <nav>
        <li><Link style={{display:'flex',alignItems:'center',justifyContent:'center'}} className='title' to='/'><img src={Logo} style={{height:'3.2rem',width:'3.2rem',objectFit:'contain'}} alt="" /><h2 style={{fontWeight:'bold',paddingRight:'1rem',paddingLeft:'1rem'}}>{t("header.NavBarName")}</h2></Link></li>
        {/* <h2 style={{color:'#fff'}}></h2> */}
        <div className='hamburger' onClick={() => {
          setMenuOpen(!menuOpen);
        }}
          
          style={{position:'absolute',top:'1.3rem',right:'1rem', color:'#000000'}}> <FaBars style={{fontSize:'2rem', color:'#fff'}} /></div>
        
        <ul id="nav-ul" className={menuOpen ? 'open' : ''}>
       
            <li  className='listInNav'><NavLink to='/' >{t("header.navHome")} </NavLink></li>
            
            <li className='listInNav'><NavLink to="/about">{t("header.navAbout")} </NavLink></li>
            <li className='listInNav'><NavLink to="members" > {t("header.navMembers")}</NavLink></li>
            <li className='listInNav'><NavLink to="/schemes"> {t("header.navSchemes")}</NavLink></li>
            <li className='listInNav'><NavLink to="/gallery">{t("header.navGallery")} </NavLink></li>
            <li className='listInNav'><NavLink to='/contact'> {t("header.navContact")} </NavLink></li>
        </ul>
    </nav>
  )
}
