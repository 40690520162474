import React from 'react'
// import { WhatsApp } from '@mui/icons-material'
import {FaFacebook, FaInstagram, FaTwitter} from 'react-icons/fa'


const FloatingBtn = () => {
  return (
    <>
       <div className="icons-float" style={{position:'fixed',bottom:'49px',right:'0',zIndex:'100',borderRadius:'50px'}}>
        <div >
        <a href="https://www.facebook.com/profile.php?id=61553283022883">  <FaFacebook style={{fontSize:'2rem',backgroundColor:'#0f172a',color:'#fff', padding:'0.2rem' }} /></a> 
        
        </div>
       </div>
    </>
  )
}

export default FloatingBtn