import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

export default function App() {
  const [t, i18n] = useTranslation('global');
  return (
    <>
    <MDBFooter style={{backgroundColor:'#006494', color:'#fff',zIndex:'10000000 !important'}}  className='text-center text-lg-start text-muted'>
      <section style={{backgroundColor:'#006494', color:'#fff',zIndex:'10000000 !important'}} className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div >
          <a href='https://www.facebook.com/profile.php?id=61553283022883' className='me-4 text-reset'>
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='https://twitter.com/kudusgrmpanchyt' className='me-4 text-reset'>
            <MDBIcon fab icon="twitter" />
          </a>
          {/* <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="google" />
          </a> */}
          <a href='https://www.instagram.com/grampanchayat_kudus/' className='me-4 text-reset'>
            <MDBIcon fab icon="instagram" />
          </a>
          {/* <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="linkedin" />
          </a> */}
         
        </div>
      </section>

      <section className='' style={{backgroundColor:'#006494', color:'#fff'}}>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                {t("ftr.ftrH")}
              </h6>
              <p>
               {t("ftr.ftrPara")}
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>{t("ftr.ftrLinks")}</h6>
              <p>
               <Link to="/" style={{color:'#fff',textDecoration:'none'}} > {t("ftr.ftrLink1")}</Link>
              </p>
              <p>
              <Link to="/" style={{color:'#fff',textDecoration:'none'}} > {t("ftr.ftrLink2")}</Link>
              </p>
              <p>
              <Link to="/" style={{color:'#fff',textDecoration:'none'}} > {t("ftr.ftrLink3")}</Link>
              </p>
              <p>
               
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>{t("ftr.ftrLinks")}</h6>
              <p>
              <Link to="/" style={{color:'#fff',textDecoration:'none'}} > {t("ftr.ftrLink4")}</Link>
              </p>
              <p>
              <Link to="/" style={{color:'#fff',textDecoration:'none'}} > {t("ftr.ftrLink5")}</Link>
              </p>
              <p>
              <Link to="/" style={{color:'#fff',textDecoration:'none'}} > {t("ftr.ftrLink6")}</Link>
              </p>
              <p>
              
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>{t("ftr.ftrContactH")}</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                {t("ftr.ftrAddr")}
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                {t("ftr.ftrEmail")}
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" />  {t("ftr.ftrPhone")}
              </p>
             
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: '#003554', color:'#fff' }}>
        © 2023 Copyright :
        <a style={{paddingLeft:'0.5rem'}} className='text-reset fw-bold' href='https://smartechsolutions.in'>
           SmarTech Solutions
        </a>
      </div>
    </MDBFooter>
    </>
  );
}