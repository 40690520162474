import React from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './Schemes.css'
import { Link } from 'react-router-dom';
import LangBtn from './LangBtn';
import { useTranslation } from 'react-i18next'
import FloatingBtn from "../FloatingBtn"
import FloatingBtn2 from "../FloatingBtn2"
import FloatingBtn3 from "../FloatingBtn3"



export const Schemes = () => {

 
  const [t, i18n] = useTranslation('global');
  const handleLanguage =(language: string ) => {
    i18n.changeLanguage(language);
    }

    
   

  return (
    <div>
      <LangBtn/>
      <FloatingBtn/>
    <FloatingBtn2/>
    <FloatingBtn3/>
      <div className="container text-center mt-5"><h1>Schemes</h1></div>
      <div className="container mt-3 mb-5">
        <div className="row">
          <div className="col-md-4">
          <Card style={{ width: '100%', height:'10rem',marginTop:'1rem' }}>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body style={{background:'linear-gradient(to bottom, #3498db, #2980b9)',color:'#fff'}}>
        <Card.Title> <h2>{t("scheme1.scheme1H2")}</h2> </Card.Title>
        {/* <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text> */}
        <Button className='btn-cards' style={{backgroundColor:'#ffb703'}} variant="primary"><Link to="/scheme1" style={{listStyle:'none',color:'#fff',textDecoration:'none'}} >{t("home.readMore")}</Link></Button>
      </Card.Body>
    </Card>

          </div>
          <div className="col-md-4">
          <Card style={{ width: '100%', height:'10rem',marginTop:'1rem' }}>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body style={{background:'linear-gradient(to bottom, #3498db, #2980b9)',color:'#fff'}}>
        <Card.Title> <h2>{t("scheme2.s2H2")}</h2> </Card.Title>
        {/* <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text> */}
        <Button className='btn-cards' style={{backgroundColor:'#ffb703'}} variant="primary"><Link to="/scheme2" style={{listStyle:'none',color:'#fff',textDecoration:'none'}} >{t("home.readMore")}</Link></Button>
      </Card.Body>
    </Card>


          </div>
          <div className="col-md-4">
          <Card style={{ width: '100%', height:'10rem',marginTop:'1rem',   }}>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body style={{background:'linear-gradient(to bottom, #3498db, #2980b9)',color:'#fff'}} >
        <Card.Title> <h2>{t("scheme3.s3H2")}</h2></Card.Title>
        {/* <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text> */}
        <Button className='btn-cards' style={{backgroundColor:'#ffb703'}} variant="primary"><Link to="/scheme3" style={{listStyle:'none',color:'#fff',textDecoration:'none'}} >{t("home.readMore")}</Link></Button>
      </Card.Body>
    </Card>


          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-4">
          <Card style={{ width: '100%', height:'10rem',marginTop:'1rem'  }}>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body style={{background:'linear-gradient(to bottom, #3498db, #2980b9)',color:'#fff'}}>
        <Card.Title> <h2>{t("scheme4.s4H2")}</h2> </Card.Title>
        {/* <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text> */}
       <Button className='btn-cards' style={{backgroundColor:'#ffb703'}} variant="primary"><Link to="/scheme4" style={{listStyle:'none',color:'#fff',textDecoration:'none'}} >{t("home.readMore")}</Link></Button>
      </Card.Body>
    </Card>


          </div>
          <div className="col-sm-4">
          <Card style={{ width: '100%', height:'10rem',marginTop:'1rem'  }}>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body style={{background:'linear-gradient(to bottom, #3498db, #2980b9)',color:'#fff'}}>
        <Card.Title> <h2>{t("scheme5.s5H2")}</h2> </Card.Title>
        {/* <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text> */}
        <Button className='btn-cards' style={{backgroundColor:'#ffb703'}} variant="primary"><Link to="/scheme5" style={{listStyle:'none',color:'#fff',textDecoration:'none'}} >{t("home.readMore")}</Link></Button>
      </Card.Body>
    </Card>


          </div>
          <div className="col-sm-4">
          <Card style={{ width: '100%', height:'10rem',marginTop:'1rem'  }}>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body style={{background:'linear-gradient(to bottom, #3498db, #2980b9)',color:'#fff'}}>
        <Card.Title> <h2>{t("scheme6.s6H2")} </h2></Card.Title>
        {/* <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text> */}
       <Button className='btn-cards' style={{backgroundColor:'#ffb703'}} variant="primary"><Link to="/scheme6" style={{listStyle:'none',color:'#fff',textDecoration:'none'}} >{t("home.readMore")}</Link></Button>
      </Card.Body>
    </Card>

          </div>
        </div>
      </div>
    </div>
  )
}
