import React from 'react'
import { useTranslation } from 'react-i18next'
import LangBtn from './LangBtn';

function Scheme2() {
  const [t, i18n] = useTranslation('global');



  return (
    <div>
      <div className="h2Scheme2"><h2>{t("scheme2.s2H2")}</h2></div>
      <div className="scheme2paras">
        <p>{t("scheme2.s2paraMain")}</p>
        <h4>{t("scheme2.s2EligibH4")}</h4>
        <p>{t("scheme2.s2elip1")}</p>
        <p>{t("scheme2.s2elip2")}</p>
        <p>{t("scheme2.s2elip3")}</p>
        <h4>{t("scheme2.s2begH4")}</h4>
        <p>{t("scheme2.s2benefp1")}</p>
        <p>{t("scheme2.s2benefp2")}</p>
        <h4>{t("scheme2.s2docReqH4")}</h4>
        <p>{t("scheme2.s2docp1")}</p>
        <p>{t("scheme2.s2docp2")}</p>
      </div>
      





      <LangBtn/>
    </div>
  )
}

export default Scheme2