import React, { useEffect,useState } from 'react'
// import CarouselPage from '../CarouselPage'
import { img4 } from '../GalleryImages'
import Aos from 'aos'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'aos/dist/aos.css'
import CardCarousel from './CardCarousel'
import { useTranslation } from 'react-i18next'
import LangBtn from './LangBtn'
import { img5,img20,img13 } from '../GalleryImages' 
import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom'
import FloatingBtn from '../FloatingBtn'
import FloatingBtn2 from '../FloatingBtn2'
import FloatingBtn3 from '../FloatingBtn3'



const Home = () => {
  useEffect(()=>{
    Aos.init({duration:2000})
  },[])
  // storing in cookie
  // function getCookie(name) {
  //   const cookies = document.cookie.split(';');
  //   for (let i = 0; i < cookies.length; i++) {
  //     const cookie = cookies[i].trim();
  //     console.log(cookie);
  //     if (cookie.startsWith(name + '=')) {
  //       return cookie.substring(name.length + 1);
  //     }
  //   }
  //   return null;
  // }
  
//  function para() {
//   const Lang = getCookie('lang');
//   //document.getElementById("selectedvalue").value = Lang;

//   if(Lang ==='Marathi'){
//     return data;
//   }
//   else {

//     return dataEng;
//   }
  
//  }

//  const [selectedOption, setSelectedOption] = useState(''); // Initialize the state

 
//  const LangFunc = (event) => {
//    const selectedValue = event.target.value;
//    setSelectedOption(selectedValue); // Update the state with the selected value
//    console.log(selectedValue); // Log the selected value
//    document.cookie = "lang=" + selectedValue; 
//   window.location.reload();
//    para();
//  };

  
//   var data = 'अद्सद्स्स्न ';
//   var dataEng = 'abcd';


//  const dataOp = para();


// //  const [publisher, setPublisher] = useState([]);
// //  apiFetch();
 
// // function apiFetch(){
// // console.log('ok');
// //   fetch('https://api.crossref.org/works?filter=has-full-text:true&mailto=GroovyBib@example.org')
// //   .then((res)=>res.json()).then((data)=>{
// //     // console.log(data.message.items[0])
// //     const containerTitle = data.message.items;   

// //     // for (let i =0; i<containerTitle.length;i ++){
// //     //   console.log(containerTitle[i]['publisher']);
// //     //   publisher.push(containerTitle[i]['publisher']);
// //     // }
// // var pubdata = '';

// // for (let i = 0; i < containerTitle.length; i++) {
// //   console.log(containerTitle[i]['publisher']);
// //   publisher.push(containerTitle[i]['publisher']);  
// //   pubdata += `<span style="background: red; padding: 1px 10px">${publisher}</span>`;
// // }
// // document.getElementById("fetchpublesherdata").innerHTML = pubdata;



// setPublisher(publisher);
// console.log(publisher);
//   })
// }
// ///////////  i18next try
const [t, i18n] = useTranslation('global');

const handleLanguage =(language: string ) => {
i18n.changeLanguage(language);

}



  return (
    <>
    <LangBtn/>
   <FloatingBtn/>
   <FloatingBtn2/>
   <FloatingBtn3/>
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-sm-8">
          <div className="headingAbout"><h1 style={{fontWeight:'bold'}}>{t("home.aboutSectionheading")}</h1></div>
          <div className="paraAbout">
            {/* <p>{dataOp}</p> */}
           {/* <select name="" id=""><option onChange={()=> handleLanguage("en")} value="">English</option><option onChange={()=> handleLanguage("em")} value="">Marathi</option></select> */}
            
         
          <p>{t("home.aboutTextParaOne")}</p>
           <p>{t("home.aboutTextParaTwo")} </p>
           </div>
          </div>
        <div className="col-sm-4" data-aos="fade-up"
     
    >
          <img  style={{width:'100%', height:'15rem', objectFit:'cover'}} src={img4} alt="" />
        </div>
      </div>
    </div>
    <div className="container"><h1 style={{fontWeight:'700'}} >{t("home.gallery")} </h1></div>
    <div className="container" style={{backgroundColor:'#8ecae6',marginBottom:'3rem'}}>
    <div className="row">
      <div className="col-sm-4"style={{paddingTop:'1rem', paddingBottom:'1rem'}}> <Card  >
      <Card.Img variant="top" src={img5} style={{height:'20rem',objectFit:'cover'}} />
    </Card>
    </div>
    <div className="col-sm-4" style={{paddingTop:'1rem', paddingBottom:'1rem'}}> <Card >
      <Card.Img variant="top" src={img13}  style={{height:'20rem',objectFit:'cover'}}/>
    </Card>
    </div>
    <div className="col-sm-4" style={{paddingTop:'1rem', paddingBottom:'1rem'}}> <Card >
      <Card.Img variant="top" src={img20} style={{height:'20rem',objectFit:'cover'}} />
    </Card>
    </div>
    </div>
    </div>
    <div className="container"  style={{marginBottom:'3rem'}}>
    <div className="container"><h1 style={{fontWeight:'700'}} >{t("home.Yojna")} </h1></div>
        <div className="row">
          <div className="col-sm-4">
          
          <Card >
      <Card.Body>
        <Card.Title> <h3>{t("home.scheme1")}</h3> </Card.Title> 
        <Link to="/scheme1" style={{textDecoration:'none', backgroundColor:'blue',padding:'5px',color:'#fff',borderRadius:'5px'}} >{t("home.readMore")} </Link>
      </Card.Body>
    </Card>
          </div>
          <div className="col-sm-4">
          <Card >
      <Card.Body>
        <Card.Title> <h3>{t("home.scheme2")}</h3> </Card.Title> 
        <Link to="/scheme2" style={{textDecoration:'none', backgroundColor:'blue',padding:'5px',color:'#fff',borderRadius:'5px'}} >{t("home.readMore")} </Link>
      </Card.Body>
    </Card>
          </div>
          <div className="col-sm-4">
          <Card >
      <Card.Body>
        <Card.Title> <h3>{t("home.scheme3")}</h3> </Card.Title> 
        <Link to="/scheme1" style={{textDecoration:'none', backgroundColor:'blue',padding:'5px',color:'#fff',borderRadius:'5px'}} >{t("home.readMore")} </Link>
      </Card.Body>
    </Card>
          </div>
        </div>
      </div>
    <div className="schemes">
<CardCarousel/>

    </div>
    {/* <select onChange={LangFunc} value={selectedOption} id="selectedvalue">
      <option>Language</option>
      <option value="English" >English</option>
      <option value="Marathi">Marathi</option>
    </select> */}
     
    
    {/* <p id='fetchpublesherdata'></p> */}

    {/* <p>
        {publisher.map((value, index) => (
          <span key={index}>{value} </span>
        ))}
      </p> */}
      
    </>
  )
}

export default Home