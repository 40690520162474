import React from 'react'
import { useTranslation } from 'react-i18next'
import LangBtn from './LangBtn';
import FloatingBtn from "../FloatingBtn"
import FloatingBtn2 from "../FloatingBtn2"
import FloatingBtn3 from "../FloatingBtn3"

const About = () => {

  const [t, i18n] = useTranslation('global');
  const handleLanguage =(language: string ) => {
    i18n.changeLanguage(language);
    }


  return (
    


    <div>
      <LangBtn/>
      <FloatingBtn/>
   <FloatingBtn2/>
   <FloatingBtn3/>
     <div className="container mt-5 text-centered">
      <h1>{t('aboutus.aboutSectionheading')}</h1>
      <p>{t('aboutus.aboutTextParaOne')}</p>
      <p>{t('aboutus.aboutTextParaTwo')}</p>
     
     </div>
    </div>
  )
}

export default About