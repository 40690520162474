import React from 'react'
import ReactDOM from "react-dom";
import ReactCardCarousel from "react-card-carousel";
import {scheme1,scheme2,scheme3,scheme4,scheme5,scheme6} from '../GalleryImages'
import './CardCarousel.css'
import Carousel from 'react-grid-carousel'




 

function CardCarousel() {
  return (
    <div className='container-style' >
    {/* <ReactCardCarousel autoplay={true} autoplay_speed={2500}>
      <div className='card-style' ><img src={scheme1} alt="" /></div>
      <div className='card-style'  ><img src={scheme2} alt="" /></div>
      <div className='card-style'  ><img src={scheme3} alt="" /></div>
      <div className='card-style'  ><img src={scheme4} alt="" /></div>
      <div className='card-style' ><img src={scheme5} alt="" /></div>
      <div className='card-style' ><img src={scheme6} alt="" /></div>
    </ReactCardCarousel> */}


<Carousel cols={3} rows={1} gap={10} autoplay={2000} loop>
      <Carousel.Item>
        <img width="100%" src={scheme1} />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src={scheme2} />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src={scheme3} />
      </Carousel.Item>
      <Carousel.Item>
      <img width="100%" src={scheme4} />
      </Carousel.Item>
      <Carousel.Item>
      <img width="100%" src={scheme5} />
      </Carousel.Item>
      <Carousel.Item>
      <img width="100%" src={scheme6} />
      </Carousel.Item>
      {/* ... */}
    </Carousel>
  </div>
  )
}

export default CardCarousel

