import React from 'react'
import { useTranslation } from 'react-i18next'
import LangBtn from './LangBtn';
import "./langBtn.css";

function Scheme4() {
  const [t, i18n] = useTranslation('global');
  return (
    <div>
      <div className="scheme4H2">
        <h2>{t("scheme4.s4H2")}</h2>
        </div>
        <div className="scheme4paras">
          <p>{t("scheme4.s4paramain")}</p>
          <h4>{t("scheme4.s4eliH4")}</h4>
          <p>{t("scheme4.s4elip1")}</p>
          <p>{t("scheme4.s4elip2")}</p>
          <h4>{t("scheme4.s4benfH4")}</h4>
          <p>{t("scheme4.s4benfp1")}</p>
          <h4>{t("scheme4.s4DocH4")}</h4>
          <p>{t("scheme4.s4Docp1")}</p>
          <p>{t("scheme4.s4Docp2")}</p>
          <p>{t("scheme4.s4Docp3")}</p>
          <p>{t("scheme4.s4Docp4")}</p>
          <p>{t("scheme4.s4Docp5")}</p>
          <p>{t("scheme4.s4Docp6")}</p>
          <p>{t("scheme4.s4Docp7")}</p>
        </div>
        <LangBtn/>
    </div>
  )
}

export default Scheme4