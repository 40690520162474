import React from 'react'
import schemeImage1 from '../../images/scheme1/Sanjay-Gandhi-Niradhar-Yojana-1.png'
import { useTranslation } from 'react-i18next'
import LangBtn from './LangBtn';
import "./langBtn.css";

function Scheme1() {
  const [t, i18n] = useTranslation('global');

  // const handleLanguage =(language: string ) => {
  // i18n.changeLanguage(language);
  // }

  return (

    <div className='Scheme1'>
      <div className="schemePhoto"><img src={schemeImage1} style={{width:'100%'}} alt="" /></div>
      <div className="h2"><h2 style={{textAlign:'center',fontWeight:'700',marginTop:'3.5rem',marginBottom:"1rem"}} >{t("scheme1.scheme1H2")}</h2></div>
      <div className="paraScheme1" style={{marginRight:"5rem",marginLeft:"5rem"}}>
        <p>{t("scheme1.schm1para")}</p>
        <h4>{t("scheme1.schm1h4")}</h4>
        <p>{t("scheme1.eligbp1")}</p>
        <p>{t("scheme1.eligibp2")}</p>
        <p>{t("scheme1.eligibp3")}</p>
        <p>{t("scheme1.eligibp4")}</p>
        <p>{t("scheme1.eligibp5")}</p>
        <h4>{t("scheme1.benfith4")}</h4>
        <p>{t("scheme1.benefitpara1")}</p>
        <p>{t("scheme1.benefitpara2")}</p>
        <h4>{t("scheme1.DocumentReqH4")}</h4>
        <p>{t("scheme1.docpara1")}</p>
        <p>{t("scheme1.docpara2")}</p>
        <p>{t("scheme1.docpara3")}</p>
        <p>{t("scheme1.docpara4")}</p>
        <p>{t("scheme1.docpara5")}</p>
        <p>{t("scheme1.docpara6")}</p>
      </div>
      {/* <div className="selectLang" style={{margin:"0rem 5rem"}}><span>Select Language</span>
      <button className='LangChangeBtn' style={{padding:'2px 5px',margin:'0rem 1rem 0rem 1rem',backgroundColor:"pink"}} onClick={()=> handleLanguage("en")}>English</button>
            <button className='LangChangeBtn' onClick={()=> handleLanguage("em")}>Marathi</button>
            </div> */}
            <LangBtn/>
    </div>
  )
}

export default Scheme1