import './Contact.css'
import React, { useRef } from 'react';
import { MDBInput, MDBBtn,MDBTextArea } from 'mdb-react-ui-kit';
import FloatingBtn from "../FloatingBtn"
import FloatingBtn2 from "../FloatingBtn2"
import FloatingBtn3 from "../FloatingBtn3"
import LangBtn from './LangBtn';



export default function App() {
const form = useRef()

const sendEmail = ()=>{
  
}
  return (
    <div>
       <LangBtn/>
   <FloatingBtn/>
   <FloatingBtn2/>
   <FloatingBtn3/>
    
    <div className="container formContainer mt-5 mb-5">
    
    <div className="row" style={{width:'100%'}}>
      <div className="col-sm-6">
      <form ref={form} onSubmit={sendEmail} id='form' className='text-center' method='' action='./mail.php' style={{ width: '100%', maxWidth: '500px' }}>
      <h2>Contact us</h2>

      <MDBInput label='Name' v-model='name' wrapperClass='mb-4' />

      <MDBInput type='email' label='Email address' v-model='email' wrapperClass='mb-4' />

      <MDBInput label='Subject' v-model='subject' wrapperClass='mb-4' />

      <MDBTextArea wrapperClass='mb-4' label='Message' />

      {/* <MDBCheckbox wrapperClass='d-flex justify-content-center' label='Send me copy' /> */}

      <MDBBtn color='primary' block className='my-4'>
        Send
      </MDBBtn>
    </form>
      </div>
      <div className="col-sm-6">
      <div className="map" style={{width:'100%',height:'100%'}}>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42612.12756028692!2d73.09805493506596!3d19.52621524741404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7754556488251%3A0x955f6270bc272305!2sKudus%2C%20Maharashtra%20421312!5e0!3m2!1sen!2sin!4v1698839064370!5m2!1sen!2sin" style={{width:'100%', height:'100%', paddingLeft:'0rem'}} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
        </div>
    </div>
    

   
    </div>
  
   
   </div>
  );
}