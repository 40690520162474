import React from 'react'
import { useTranslation } from 'react-i18next'
import LangBtn from './LangBtn';
import "./langBtn.css";

function Schemes3() {
  const [t, i18n] = useTranslation('global');
  return (
    <div>
      <div className="scheme3h2"><h2>{t("scheme3.s3H2")}</h2></div>
      <div className="scheme3paras">
        <p>{t("scheme3.s3paramain")}</p>
        <h4>{t("scheme3.s3eliH4")}</h4>
        <p>{t("scheme3.s3elip1")}</p>
        <p>{t("scheme3.s3elip2")}</p>
        <p>{t("scheme3.s3elip3")}</p>
        <p>{t("scheme3.s3elip4")}</p>
        <h4>{t("scheme3.s3benfH4")}</h4>
        <p>{t("scheme3.s3benfp1")}</p>
        <p>{t("scheme3.s3benfp2")}</p>
        <h4>{t("scheme3.s3DocH4")}</h4>
        <p>{t("scheme3.s3Docp1")}</p>
        <p>{t("scheme3.s3Docp2")}</p>
        <p>{t("scheme3.s3Docp3")}</p>
        <p>{t("scheme3.s3Docp4")}</p>
        <p>{t("scheme3.s3Docp5")}</p>
      
      </div>
      <LangBtn/>
    </div>
  )
}

export default Schemes3