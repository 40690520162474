import React from 'react'
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import img1 from '../images/carouselImages/1.jpeg'
import img2 from '../images/carouselImages/2.jpeg'
import img3 from '../images/carouselImages/3.jpeg'
import img4 from '../images/carouselImages/4.jpeg'
import img5 from '../images/carouselImages/5.jpeg'
import img6 from '../images/carouselImages/6.jpeg'
import { img48 } from './GalleryImages';

function CarouselPage() {
  return (
    <div >
   <Carousel data-bs-theme="dark" style={{zIndex:'100000'}}>
      <Carousel.Item>
        <img style={{height:'29rem', objectFit:'cover'}}
          className="d-block w-100"
          src={img1}
          alt="First slide"
        />
        <Carousel.Caption>
          {/* <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img style={{height:'29rem', objectFit:'cover'}}
          className="d-block w-100"
          src={img48}
          alt="First slide"
        />
        <Carousel.Caption>
          {/* <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img style={{height:'29rem', objectFit:'cover'}}
          className="d-block w-100"
          src={img2}
          alt="Second slide"
        />
        <Carousel.Caption>
          {/* <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img style={{height:'29rem', objectFit:'cover'}}
          className="d-block w-100"
          src={img3}
          alt="Third slide"
        />
        <Carousel.Caption>
          {/* <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img style={{height:'29rem', objectFit:'cover'}}
          className="d-block w-100"
          src={img4}
          alt="First slide"
        />
        <Carousel.Caption>
          {/* <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img style={{height:'29rem', objectFit:'cover'}}
          className="d-block w-100"
          src={img5}
          alt="First slide"
        />
        <Carousel.Caption>
          {/* <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img style={{height:'29rem', objectFit:'cover'}}
          className="d-block w-100"
          src={img6}
          alt="First slide"
        />
        <Carousel.Caption>
          {/* <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
</div>
  )
}

export default CarouselPage